import {TableBase} from "../../tables/table_base";
import { EarnedRecognition } from '../../er'

class EarnedRecognitionDriverPane extends TableBase {
  constructor() {
    super('er_driver');
  }

  paintTable($erDrivers, er_drivers) {
    try {
      if (!app.EARNED_RECOGNITION) {
        new EarnedRecognition();
      }

      app.DOM.earned = app.DOM.content.find('#er');
      const $yearSelector = app.DOM.earned.find('#opr-ear-years');
      $yearSelector.off('change').on('change', (e) => app.EARNED_RECOGNITION.displayEarYearTable($(e.target).val()));
      const years = Object.keys(er_drivers);

      const yearOptions = years.reverse().map((year) => new Option(year, year));
      yearOptions[0].selected = true;
      $yearSelector.append(...yearOptions);

      const $container = app.DOM.earned.find('div.grid-stack-item-content-inner')
      $container.find('table.loading').hide();
      $erDrivers = $container.find('table#er_driver')
      $erDrivers.remove()

      const tables = years.map((year) => $erDrivers.clone().prop('id', `tbl-opr-ear-${year}`).addClass('hide'));
      $container.append(...tables);

      for (const year of years) {
        const table = $container.find(`#tbl-opr-ear-${year}`);
        const data = er_drivers[year];

        app.EARNED_RECOGNITION.renderEarnedRecognitionDataIntoTable(data, year, table);
      }

      app.EARNED_RECOGNITION.displayEarYearTable($yearSelector.val());
      app.EARNED_RECOGNITION.setupTooltipsER();
    } catch(err) {console.error(err)}
  }
}

$(() => {
  if(app && app.DASHBOARD_CORE) {
    new EarnedRecognitionDriverPane();
  } else {
    console.warn('DashboardCore not initalized.');
  }
});
